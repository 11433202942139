.video-player {
  background-color: black;
  width: 100%;
  height: 50vh;
  scroll-margin-top: 200px;
  margin-bottom: 10px
}

.work-card {
    display: flex;
    position: relative;
    padding: 0;
    height: 100%;
    flex-direction: column;
  }
  
  .work-image-container {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    height: 0;
    padding-bottom: 111%; /* Set a fixed aspect ratio (4:3 in this case) */
  }

  .work-card.work-16-9 .work-image-container {
    padding-bottom: 55%; /* 16:9 aspect ratio (9 divided by 16) */
  }

  .work-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .work-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(0, 0, 0, 0.7); */
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
  }
  
  .work-image-container:hover .work-overlay {
    opacity: 1;
  }
  
  .work-overlay-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .work-overlay-content h4,
  .work-overlay-content h6 {
    margin: 0;
    color: white;
  }
  
  .work-overlay-content h4 {
    font-size: 20px;
  }
  
  .work-overlay-content h6 {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .work-overlay-content a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
  }
  
  .work-overlay-content a:hover {
    text-decoration: underline;
  }


  /* @media (min-width: 576px) {
    .work-card .card-img-overlay {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
    .work-card .work-overlay-content {
      opacity: 1;
    }
  
    .work-card:hover .work-overlay-content {
      opacity: 1;
    }
  } */